<template>
  <div>
    <b-navbar toggleable="lg" :type="type" :variant="variant" fixed="top" class="p-4">
      <b-container>
        <b-navbar-brand href="#">
          <img src="../assets/logo.png" alt="" height="50" loading="lazy">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto mr-auto">
            <b-nav-item href="#" v-for="link in links" :key="link" class="px-3">{{
              link
            }}</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    links: ["Home", "About", "Products", "Services", "Contact"],
    variant: "",
    type: "dark",
    scrolled: false
  }),

  watch: {
    scrolled(val) {
      this.variant = val ? "dark" : ""
    }
  },

  created() {
      window.addEventListener('scroll', () => {
          this.scrolled = ((window.pageYOffset || document.documentElement.scrollTop) > 50);
          // this.variant = ((window.pageYOffset || document.documentElement.scrollTop) > 50) ? "dark" : "";
        //   this.type = ((window.pageYOffset || document.documentElement.scrollTop) > 50) ? "dark" : "light";
      })
  }
};
</script>

<style lang="scss">
.navbar-brand {
  img {
    filter: brightness(10);
  }
}

.nav-link {
  color: #fff !important;
}
</style>