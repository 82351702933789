<template>
  <section
    class="h-100 d-flex flex-column justify-content-end align-items-start section"
  >
    <div class="container mx-0 mb-5 pb-5">
      <h1>CONCEIVING, DESIGNING, PRODUCING, METAL COMPONENTS SUITABLE FOR ENTERIOR AND EXTERIOR FURNISHINGS. </h1>
      <h2>CUSTOMER SEGMENTATION THAT MEAN CLASIFY CUSTOMER ON THE BASIS ON THEIR COMERCIAL POTENTIAL RATHER THAN THEIR IMPORTANCE FOR THE PURPOCES OF COMMUNICATION AND RECOGNITION. BY DEFINITION ALWAYS TAKE A NOTE FOR THE QUALITY DEFINED IN THE CORE OF THE COMPANY DATABASE.</h2>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  background: url("../assets/img/bg5.jpg") center center;
  background-size: cover;
  color: #fff;
}

h1 {
  font-size: 2.8rem;
  font-weight: 300;
  text-shadow: 2px 2px #000;
  text-align: left;
}

h2 {
  text-align: left;
}

.gray {
    color: rgba(255,255,255, .6);
}
</style>
