<template>
  <section
    class="h-100 d-flex flex-column justify-content-center align-items-center section"
  >
    <div class="container">
      <h1>WELCOME TO <span>GMK TECHNOLOGY</span></h1>
      <h5 class="gray mb-5">
        Nullam aliquet mollis hendrerit. Maecenas porttitor, libero ut
        ullamcorper scelerisque, nibh libero molestie augue, non volutpat libero
        diam at leo.
      </h5>
      <button class="btn btn-lg btn-primary mr-5 px-5 py-3">Products</button>
      <button class="btn btn-lg btn-secondary px-5 py-3">Services</button>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  background: url("../assets/img/DJI_0329.jpg") center center;
  background-size: cover;
  color: #fff;
}

h1 {
  font-size: 4.5rem;
  font-weight: 600;
  text-shadow: 2px 2px #000;

  span {
    color: #A38A60;
  }
}

.gray {
    color: rgba(255,255,255, .6);
}
</style>
