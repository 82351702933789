<template>
  <section
    class="h-100 d-flex flex-column justify-content-end align-items-center section"
  >
    <div class="container">
      <h1>LATEST GENERATION EQUIPMENT AND MACHINERY, CAPABLE OF PRODUCING AT COMPETITIVE COST, QUICKLY AND WITH THE QUALITY REQUIRED BY THE CUSTOMERS <br>
<span>WORK EXPERIENCE REFLECTED IN THE SATISFACTION OF OUR CUSTOMERS</span></h1>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  background: url("../assets/img/bg3.jpg") center center;
  background-size: cover;
  color: #fff;
}

h1 {
  font-size: 2rem;
  font-weight: 300;
  text-shadow: 2px 2px #000;
  text-align: left;
  margin-bottom: 3rem;

  span {
    color: #A38A60;
  }
}

.gray {
    color: rgba(255,255,255, .6);
}
</style>
