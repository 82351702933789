<template>
  <div id="app">
    <navbar></navbar>
    <div class="fullpage-container">
      <div class="fullpage-wp" v-fullpage="opts" ref="example">
        <home class="page"></home>
        <page-two class="page"></page-two>
        <page-three class="page"></page-three>
        <page-four class="page"></page-four>
        <page-five class="page"></page-five>7
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Navbar from "@/components/Navbar.vue";
import Home from "@/views/Home.vue";
import PageTwo from '@/views/PageTwo.vue';
import PageThree from '@/views/PageThree.vue';
import PageFour from '@/views/PageFour.vue';
import PageFive from '@/views/PageFive.vue';

export default {
  name: "App",
  components: {
    Home,
    Navbar,
    PageTwo,
    PageThree,
    PageFour,
    PageFive
  },
  data: () => ({
    opts: {
      start: 0,
      dir: "v",
      duration: 500,
      beforeChange: () => {},
      afterChange: () => {},
    },
  }),
  methods: {
    moveNext(){
      this.$refs.example.$fullpage.moveNext(); //Move to the next page
    },
    movePrev(){
      this.$refs.example.$fullpage.movePrev(); //Move to the next page
    }
  },

  created() {
    const that = this;
    document.addEventListener('keydown', function(e) {
      e.key.match(/PageDown|ArrowDown/gi)  && that.moveNext();
      e.key.match(/PageUp|ArrowUp/gi)  && that.movePrev();
    });
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,600;1,400&display=swap");

* {
  font-family: "Exo 2", sans-serif !important;
}

html,
body {
  height: 100%;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fullpage-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.page-1,
.page-3 {
  background: #ccc;
}
</style>
