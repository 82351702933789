<template>
  <section
    class="h-100 d-flex flex-column justify-content-center align-items-start section"
  >
    <div class="container mx-0">
      <h1>PRODUCING FINISHED, SEMI-FINISHED PRODUCTS, OBTAINED FROM STEEL AND / OR ALUMINIUM PIPES FOR THE PRODUCTION OF COMPONENTS SUITABLE FOR <span>INTERIOR DESIGN</span> AND <span>OUTDOOR FURNITURE</span></h1>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 20rem;
}
section {
  background: url("../assets/img/bg4.jpg") center center;
  background-size: cover;
  color: #fff;
}

h1 {
  font-size: 3rem;
  font-weight: 300;
  text-shadow: 2px 2px #000;
  text-align: left;

  span {
    font-weight: 600;
    color: #A38A60;
  }
}

.gray {
    color: rgba(255,255,255, .6);
}
</style>
