<template>
  <section
    class="h-100 d-flex flex-column justify-content-center align-items-center section"
  >
    <div class="container">
      <h1><span>3400</span> SQUARE METERS PRODUCTION FACILITIES </h1>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  background: url("../assets/img/DJI_0357.jpg") center center;
  background-size: cover;
  color: #fff;
}

h1 {
  font-size: 4.5rem;
  font-weight: 300;
  text-shadow: 2px 2px #000;
  span {
    font-weight: bold;
    color: #A38A60;
  }
}

.gray {
    color: rgba(255,255,255, .6);
}
</style>
